import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./pages/Home";
import AccountTypeMt5 from "./pages/AccountType/AccountTypeMt5";
import TradableInst from "./pages/AccountType/TradableInst";
import SpreadDetails from "./pages/AccountType/SpreadDetails";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/mt5-account-types" component={AccountTypeMt5} />
          <Route exact path="/tradable-instruments" component={TradableInst} />
          <Route exact path="/spread-details" component={SpreadDetails} />
        </Switch>
      </Router>
    </>
  );
}

export default App;

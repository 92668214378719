import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo.svg";
import "./header.css";
import { Link } from 'react-router-dom';

const Header = () => {
  const [activeLink, setActiveLink] = useState('');

  const hide = (value) => {
    return (value === activeLink) ? '' : "submenu_div_hide";
  }

  useEffect(() => {
    const barY = window.scrollY + document.querySelector('.down_nav').getBoundingClientRect().top // Y

    window.addEventListener('mousemove', (e) => {
      (e.clientY > barY + 120) ? setActiveLink('') : (e.clientY < barY) && setActiveLink('');
    })
  }, []);

  return (
    <>
      <header>
        <nav className="navbar">
          <div className="container">
            <NavLink to="/" className="navbar-brand" lg={6}>
              <img src={logo} className="d-inline-block align-top" alt="" />
            </NavLink>
            <nav className="my-auto" lg={4}>
              <button className="hm-btn1">
                <span> Open Live Account</span>
              </button>
              <button className="hm-btn">
                <span>Open Demo Account</span>
              </button>
            </nav>
          </div>
        </nav>
        <div className="down_nav" >
          <ul className="fst_sub">
            <li onMouseEnter={() => setActiveLink('1')} >
              <NavLink to="/">Account types</NavLink>
            </li>
            <li onMouseEnter={() => setActiveLink('2')}>
              <NavLink to="/">Funding</NavLink>
            </li>
            <li onMouseEnter={() => setActiveLink('')}>
              <NavLink to="/">Trading platforms</NavLink>
            </li>
            <li onMouseEnter={() => setActiveLink('')}>
              <NavLink to="/">Promotion</NavLink>
            </li>
            <li onMouseEnter={() => setActiveLink('3')}>
              <NavLink to="/">Invest</NavLink>
            </li>
            <li onMouseEnter={() => setActiveLink('')}>
              <NavLink to="/">Forex Tools</NavLink>
            </li>
            <li onMouseEnter={() => setActiveLink('')}>
              <NavLink to="/">Client Support</NavLink>
            </li>
            {/* <li>
              <button className="login-btn btn1">Login</button>
            </li> */}

            <button className="login-btn btn1">Login</button>
          </ul>
          <div className={`submenu_div ${hide('1')}`}>
            <ul className="submenu_1">
              <li>
                <Link to="/mt5-account-types" href="index.html">MT5 Account Types</Link>
              </li>
              <li>
                <Link to="/tradable-instruments">Tradable Instruments</Link>
              </li>
              <li>
                <Link to="/spread-details">Spread Details</Link>
              </li>
            </ul>
          </div>

          <div className={`submenu_div ${hide('2')}`}>
            <ul className="submenu_1">
              <li>
                <a href="index.html">Deposit {'&'} Withdrawal</a>
              </li>
            </ul>
          </div>

          <div className={`submenu_div ${hide('3')}`}>
            <ul className="submenu_1">
              <li>
                <a href="index.html">Welcome Bonus</a>
              </li>
              <li>
                <a href="index-v2.html">Withdrawal Bonus</a>
              </li>
              <li>
                <a href="index-v3.html">Free Deposit $50</a>
              </li>
            </ul>
          </div>

        </div>
      </header>
    </>
  );
};

export default Header;

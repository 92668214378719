import { useState } from 'react';

import './styles.css';

import form from '../../assets/form.svg';
import address from '../../assets/address.svg';
import done from '../../assets/done.svg';

export const AccountSteps = () => {
  const [step, setStep] = useState(1);

  const steps = (value) => {
    return (
      <>
        {(step === value) ? (
          <div className='triangle tposition'><p style={{ right: `${-6 + (-(value * 2))}px` }}>{value}</p></div>
        ) : (
          <h1 onClick={() => setStep(value)}>{value}</h1>
        )}
      </>
    )
  }

  return (
    <div className='accRoot'>

      {/* Banner */}
      <div className='container'>
        <div className='container accbanner'>
          {(step === 1) ? (
            <>
              <img src={form} width="100px" />
              <p>Fill out the Forms {`&`} <br /> Confirm Your Email</p>
            </>
          ) : (step === 2) ? (
            <>
              <img src={address} width="100px" />
              <p>Upload Your Address <br /> Proof and ID Proof</p>
            </>
          ) : (
            <>
              <img src={done} width="100px" />
              <p>Your Trading Account will be <br /> Created {`&`} Happy Trading</p>
            </>
          )}
        </div>
      </div>

      {/* Bar */}
      <div className="barRoot">
        <div className="container">
          <div className='container barContainer'>
            <div className='barHead'>
              <h1>3</h1>
              <h4>Minutes <br />{`&`}<br /> Steps</h4>
            </div>

            <div className='barSteps'>
              {steps(1)}

              <div className='horizontal'></div>

              {steps(2)}
              <div className='horizontal'></div>

              {steps(3)}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}